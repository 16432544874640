import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Navigation = () => (
  <StyledNavigation>
    <Link activeClassName="active" to="/">home</Link>
    <Link activeClassName="active" to="/music">music</Link>
    <a target="_blank"rel="noopener noreferrer" href="https://shop.kayladivenere.com">shop</a>
    <Link activeClassName="active" to="/contact">contact</Link>
  </StyledNavigation>
)

const StyledNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 26px;
  margin: 5px auto 15px auto;
  max-width: 600px;
  width: 100%;

  a {
    color: ${({ theme }) => theme.color.red};
    text-decoration: none;
    font-weight: bold;
  }

  a:visited {
    color: ${({ theme }) => theme.color.red};
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => theme.size.md}px) {
    font-size: 40px;
    width: 600px;
    max-width: 600px;
    position: absolute;
    top: calc(50% - 30px);
    right: calc(50%);
    flex-direction: row-reverse;
    transform: rotate(-90deg);

    .active {
      color: ${({ theme }) => theme.color.blue} !important;
    }
  }
`

export default Navigation
