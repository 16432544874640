import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Link to="/">
      <h1>{siteTitle}</h1>
    </Link>
  </StyledHeader>
)

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;

  h1 {
    display: inline-block;
    font-size: 4.5em;
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.color.red};
  }

  h1:hover {
    color: ${({ theme }) => theme.color.blue};
  }

  a {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.size.sm}px) {
    padding: 30px 0; 
    h1 {
      font-size: 8em;
    }
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
