import * as React from "react"
import styled from "styled-components"

import Instagram from '../images/instagram.svg';
import Spotify from '../images/spotify.svg';
import TikTok from '../images/tiktok.svg';
import Twitter from '../images/twitter.svg';
import Youtube from '../images/youtube.svg';

const Footer = () => (
  <StyledFooter>
    <a href="https://www.instagram.com/kayladivenere/" target="_blank" rel="noreferrer noopener">
      <Instagram />
    </a>
    <a href="https://open.spotify.com/artist/6LmymAfsFtt2CaJSj9hdCn" target="_blank" rel="noreferrer noopener">
      <Spotify />
    </a>
    <a href="https://www.tiktok.com/@kayladivenere" target="_blank" rel="noreferrer noopener">
      <TikTok />
    </a>
    <a href="https://twitter.com/kdivenere" target="_blank" rel="noreferrer noopener">
      <Twitter />
    </a>
    <a href="https://www.youtube.com/channel/UCFMYyde1oGvKSS5bQRy0yiw" target="_blank" rel="noreferrer noopener">
      <Youtube />
    </a>
  </StyledFooter>
)

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  margin-top: auto;
  padding: 15px 0;

  a {
    svg {
      fill: ${({ theme }) => theme.color.red};
      color: ${({ theme }) => theme.color.red};
      width: 30px;
      height: 30px;
    }

    svg:hover {
      fill: ${({ theme }) => theme.color.blue};
      color: ${({ theme }) => theme.color.blue};
    }
  }

  @media (min-width: ${({ theme }) => theme.size.sm}px) {
    margin: 45px auto 0 auto;
    padding: 0;
  }
`

export default Footer
