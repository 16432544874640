import { createGlobalStyle } from "styled-components"
import LiquidoWoff from './Liquido.woff'
import LiquidoTtf from './Liquido.ttf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Liquido';
    src: url('${LiquidoWoff}') format('woff'), url('${LiquidoTtf}') format('truetype');
  }

  body {
    min-height: 100vh;
    color: white;
    background-color: black;

    h1 {
      font-family: 'Liquido';
    }
    
    h2, h3, h4, h5, h6, p, button, a {
      font-family: 'Inter';
    }

    a:visited {
      color: white;
    }
  }`