export const theme = {
  color: {
    blue: '#54ace8',
    red: '#d7271c'
  },
  size: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
  }
}