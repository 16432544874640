/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import "normalize.css"
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../styles/theme"
import { GlobalStyle } from "../styles/globalStyles"

import Header from "./header"
import Navigation from "./navigation"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledLayout>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>
          <Navigation />
          <div id="content">
            {children}
          </div>
        </main>
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  )
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100vh;

  main {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  @media (min-width: ${({ theme }) => theme.size.sm}px) {
    #content {
      display: flex;
      align-items: center;
      min-height: 666.66px;
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
